<template>
  <v-form :readonly="value.id > 0 && !canEditSetupTables">
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> Cancel </v-btn>
          <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              :value="value.name"
              v-model.trim="$v.form.name.$model"
              label="Name"
              required
              :error-messages="handleErrors($v.form.name)"
              @blur="$v.form.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :value="value.email"
              v-model.trim="$v.form.email.$model"
              label="Email"
              required
              :error-messages="handleErrors($v.form.email)"
              @blur="$v.form.email.$touch()"
              :readonly="value.readonly"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';

const newMechanic = {
  name: '',
  email: '',
};

export default {
  mixins: [validationMixin],
  data: () => ({
    handleErrors,
    panel: [0],
    id: null,
    form: newMechanic,
    address: {},
    search: null,
    saved: false,
  }),
  validations: {
    form: {
      name: { required },
      email: { required },
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newMechanic;
        if (this.$refs.aform) this.$refs.aform.clear();
        this.$emit('closeForm');
      }
    },
    handleInput(address) {
      this.address = address;
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.code || this.$v.form.$model.name)) return true;
      if (!this.id) return false;
      if (this.$v.form.$model.name != this.value.name || this.$v.form.$model.email != this.value.email) return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newMechanic,
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.form = { ...newMechanic, ...value };
        }
      },
    },
  },
};
</script>
